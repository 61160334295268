<template>
  <div class="page-content">
    <div class="row mb-4">
      <div class="col-lg-12">
        <div class="heading-section">
          <h4 class="mt-4 mb-2"><em>Community</em> Showcase</h4>
          <div class="row mb-4">
            <div class="col-md-6">
              <p>
                Discover QR Code Masterpieces! Immerse in our vibrant community's creative showcase,
                where inspiration knows no bounds. Witness unique designs, artfully combining AI-generated codes with personal prompts.
                Join us on this inspiring journey and unleash your own QR code creativity!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showCase.length" class="row mb-3">
      <div class="col-md-3 mb-3" v-for="(image, index) in showCase" :key="index">
        <div class="image-container w-100">
          <StoreImage class-name="" :clickable="false" :src="image.imageSec"></StoreImage>
          <div class="hover-details">
            <div class="row mb-1">
              <div class="col-md-6">
                <div class="user-name">{{image.displayName}}</div>
              </div>
              <div class="col-md-6">
                <div class="created-at">{{image.createdAt | formatFirestoreTimestamp}}</div>
              </div>
            </div>
            <div v-if="userLoggedIn && showPrompt">
              <p class="prompt">{{image.prompt}}</p>
            </div>
            <div v-else-if="userLoggedIn && !showPrompt">
              <p><router-link class="login-link" to="/profile#">Get tokens</router-link> to view the prompt</p>
            </div>
            <div v-else>
              <p><span class="login-link" @click="login">Login</span> to view the prompt</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-3 mb-3" v-for="index in [1,2,3,4,5,6,7,8,9,10]" :key="index">
          <b-skeleton-img class="square" width="100%"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import StoreImage from "../components/StoreImage";

export default {
  name: "Showcase",
  computed: {
    ...mapState(['showCase', 'userDetails']),
    showPrompt() {
      return this.userDetails && this.userDetails.isSubscribed;
    },
    userLoggedIn() {
      return !!this.userDetails;
    }
  },
  components: {StoreImage},
  methods: {
    ...mapActions(['loadShowcase', 'loginWithGoogle']),
    login() {
      this.loginWithGoogle();
    }
  },
  filters: {
    formatFirestoreTimestamp(timestamp) {
      if (!timestamp) {
        return "";
      }
      const now = new Date();
      const secondsElapsed = Math.floor((now - timestamp.toDate()) / 1000);
      if (secondsElapsed < 60) {
        return `${secondsElapsed} seconds ago`;
      } else if (secondsElapsed < 3600) {
        const minutes = Math.floor(secondsElapsed / 60);
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
      } else if (secondsElapsed < 86400) {
        const hours = Math.floor(secondsElapsed / 3600);
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
      } else {
        const days = Math.floor(secondsElapsed / 86400);
        return `${days} ${days === 1 ? 'day' : 'days'} ago`;
      }
    }
  },
  mounted() {
    this.loadShowcase();
  }
}
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
  display: inline-block;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
}

.hover-details {
  position: absolute;
  bottom: 10px;
  width: calc(100% - 20px);
  margin: 0 10px 0 10px;
  background: var(--background-color);
  backdrop-filter: blur(5px);
  color: var(--text-color);
  padding: 15px;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 20px;
}

.image-container:hover .hover-details {
  opacity: 1;
}

.user-name {
  color: var(--main-color);
  font-weight: bold;
  font-size: 12px;
}

.created-at {
  text-align: end;
  color: #f3f3f3;
  font-size: 12px;
}

.prompt {
  cursor: text;
  color: #fff;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Make the side scrollbar thin */
.prompt::-webkit-scrollbar {
  width: 5px;
}

/* Style the side scrollbar */
.prompt::-webkit-scrollbar-thumb {
  background: var(--main-color);
  border-radius: 5px;
}

/* Optional: Style the scrollbar track */
.prompt::-webkit-scrollbar-track {
  background: var(--background-color);
}

.square {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  border-radius: 24px !important;
  overflow: hidden;
}

.login-link {
  color: var(--main-color);
  font-weight: bold;
  text-decoration: underline !important;
  cursor: pointer;
}
</style>
